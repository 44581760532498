import './App.css'
import {
  currentAndroidVersion,
  currentIosVersion,
  currentWebVersion,
} from './ReleaseNotes'

const Nav = () => {
  const navlinkStyle = {
    color: '#000',
    textTransform: 'uppercase',
    textDecoration: 'none',
  }
  return (
    <>
      <div style={{ height: 119, width: '100%' }} />
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          padding: '48px 0px',
          justifyContent: 'space-around',
          flexDirection: 'row',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
          margin: 'auto',
          cursor: 'pointer',
          backgroundColor: 'white',
        }}
        className="TextContainer"
      >
        <a href={'/'} style={navlinkStyle}>
          Links
        </a>
        <a href={'/privacypolicy'} style={navlinkStyle}>
          Privacy Policy
        </a>
        <a href={'/releasenotes'} style={navlinkStyle}>
          Release Notes
        </a>
      </div>
      <small
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          padding: 8,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        Latest Android Version: {currentAndroidVersion} / Latest iOS Version:{' '}
        {currentIosVersion} / Latest Web Version: {currentWebVersion}
      </small>
    </>
  )
}

export default Nav
