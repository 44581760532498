import './App.css'

export const currentAndroidVersion = '2.09 (62)'
export const currentIosVersion = '2.07 (61)'
export const currentWebVersion = 'sP0w13'

const notes = `
- Fix for bug where users who left a project could not be re-invited
- Fix for bug where user could accept terms and conditions without scrolling
- New design implemented for Driver Confirm/Cancel scan
- New design implemented for Driver Delivery Received
- Added navigation bar texts
- Fix for bug where OTP fields could contain hidden spaces
- Fonts updated
`
const ReleaseNotes = () => {
  return (
    <section className="App">
      <main className="App-main">
        <header className="App-header">
          <img
            src={`../img/lokate-light-logo.svg`}
            height={28}
            //style={{ transform: [{ scale: 0.9 }] }}
          />
        </header>
        <section className="Container">
          <div className="TextContainer">
            Latest Android: {currentAndroidVersion}
          </div>
          <div className="TextContainer">Latest iOS: {currentIosVersion}</div>
          <div className="TextContainer">Latest web: {currentWebVersion}</div>
          <br></br>
          <hr></hr>
          <br></br>
          <div className="TextContainer">Release notes:</div>
          {notes.split('- ').map((line, i) => (
            <div style={{ textAlign: 'left' }} key={'note_' + i}>
              {i != 0 && '- '}
              {line}
            </div>
          ))}
        </section>
      </main>
    </section>
  )
}

export default ReleaseNotes
