import { useState } from 'react'
import './App.css'

export const themeManager = (darkMode = false) => {
  return {
    mode: !darkMode ? 'light' : 'dark',
    defaultFontColour: !darkMode ? '#414141' : '#fed',
    lightFontColour: !darkMode ? '#ffffff' : '#000',
    paleAccent: !darkMode ? '#f5f7fd' : '#33376a',
    midAccent: !darkMode ? '#dbdffc' : '#dbdffc',
    altAccent: !darkMode ? '#EAECFD' : '#8c8e98',
    accentColour: !darkMode ? '#413ddf' : '#9baacc',
    accentComplimentColour: !darkMode ? '#ff7346' : '#CCBD9B',
    greyTextColour: !darkMode ? '#6c6c6c' : '#999',
    mainBackgroundColour: !darkMode ? '#fff' : '#000437',
    errorColour: !darkMode ? '#E82E00' : 'indianred',
    errorComplimentaryColour: !darkMode ? '#FBDBD4' : '#FBDBD4',
    authHeaderBG: !darkMode ? ['white', 'white'] : ['#000', '#000'],
    subtleToBGColour: !darkMode ? '#f4f4f4' : '#000220',
    successColour: !darkMode ? '#2CC76D' : '#198754',
    successComplimentaryColour: !darkMode ? '#E9F9F0' : '#E9F9F0',
    warningColour: !darkMode ? '#FFAA00' : '#FFAA00',
    webNavy: !darkMode ? '#2e3440' : '#2e3440',
    webSilverBorders: !darkMode
      ? 'rgba(100, 150, 200,0.3)'
      : 'rgba(100, 150, 200,0.3)',
    textInputTextColour: !darkMode ? 'rgba(0, 0, 0, 0.87)' : '#ddd',
    textInputPlaceholderColour: !darkMode ? 'rgba(0, 0, 0, 0.6)' : '#ddd',
    textInputMediumColour: !darkMode ? 'rgba(0, 0, 0, 0.4)' : '#fff',
    textInputBorderColour: !darkMode
      ? 'rgba(0, 0, 0, 0.12)'
      : 'rgba(255,255,255,0.2)',
  }
}
function App() {
  const [english, setEnglish] = useState(true)

  let androidLink = 'https://groups.google.com/g/lokate-beta'
  let androidTitle = 'Download on Play Store'

  let iosLink = 'https://testflight.apple.com/join/ep0CHEFH'
  let iosTitle = 'Download on TestFlight'

  let webLink = 'https://supplier.lokate.vgcslabs.com'
  let webTitle = 'Open Supplier Web'

  return (
    <section className="App">
      <main className="App-main">
        <header className="App-header">
          <img
            src={`../img/lokate-light-logo.svg`}
            height={28}
            //style={{ transform: [{ scale: 0.9 }] }}
            alt={androidTitle}
          />
          {english ? (
            <img
              onClick={() => setEnglish(false)}
              style={{ cursor: 'pointer' }}
              className="Flag"
              src={`../img/gb.png`}
              alt="English"
            />
          ) : (
            <img
              onClick={() => setEnglish(true)}
              style={{ cursor: 'pointer' }}
              className="Flag"
              src={`../img/se.png`}
              alt="Swedish"
            />
          )}
        </header>
        <section className="Container">
          {english ? (
            <div className="TextContainer">
              <h2>Welcome to Lokate!</h2>
              <h3>
                Are you a distributor/driver or work at a construction site?
              </h3>
              <p>
                To get started, scan the code with your camera or click the link
                corresponding to the platform you are using.
              </p>
              <h3>Are you a supplier?</h3>
              <p>
                <a href={webLink} target="_blank" title={webTitle}>
                  Click here
                </a>{' '}
                to get to the
                <br />
                web page for suppliers
              </p>
            </div>
          ) : (
            <div className="TextContainer">
              <h2>Välkommen till Lokate!</h2>
              <h3>
                Är du distributör/förare eller jobbar på en byggarbetsplats?
              </h3>
              <p>
                För att komma igång, skanna koden med din kamera eller klicka på
                länken till den platform du använder.
              </p>
              <h3>Är du leverantör?</h3>
              <p>
                <a href={webLink} target="_blank" title={webTitle}>
                  Klicka här
                </a>{' '}
                för att komma till
                <br />
                webbplatsen för leverantörer
              </p>
            </div>
          )}
          <div className="Code-container">
            <div className="Code">
              <a
                href={androidLink}
                style={{ color: '#413ddf' }}
                title={androidTitle}
                target="_blank"
                rel="noreferrer"
              >
                Android - Play Store
              </a>
              <a
                href={androidLink}
                title={androidTitle}
                target="_blank"
                rel="noreferrer"
              >
                <img src={`../img/ANDROIDQR.png`} alt={androidTitle} />
              </a>
            </div>
            <div className="Code">
              <a
                href={iosLink}
                style={{ color: '#413ddf' }}
                title={iosTitle}
                target="_blank"
                rel="noreferrer"
              >
                iOS - TestFlight
              </a>
              <a
                href={iosLink}
                title={iosTitle}
                target="_blank"
                rel="noreferrer"
              >
                <img src={`../img/AIOSQR.png`} alt={iosTitle} />
              </a>
            </div>
          </div>
        </section>
      </main>
    </section>
  )
}

export default App
