import '../App.css'

function PrivacyPolicy() {
  return (
    <section className="App">
      <main className="App-main">
        <header className="App-header">
          <img
            src={`../img/lokate-light-logo.svg`}
            height={28}
            alt={'Lokate'}
          />
        </header>
        <section className="Container">
          <div className="TextContainer">
            <div>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                }}
              >
                <strong>Terms of Use</strong>
              </p>
              <ol className="decimal_type" style={{ listStyleType: 'decimal' }}>
                <li>
                  <h1
                    style={{
                      marginTop: '6.0pt',
                      marginRight: '0cm',
                      marginBottom: '6.0pt',
                      marginLeft: '42.55pt',
                      textIndent: '-42.55pt',
                      fontSize: '1.3rem',
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Background
                  </h1>
                  <ol
                    className="decimal_type"
                    style={{ listStyleType: 'decimal' }}
                  >
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        Volvo Truck Corporation is providing a{' '}
                        <span style={{ fontFamily: 'Arial' }}>
                          logistics service via&nbsp;
                        </span>
                        a mobile application and/or web interface where
                        companies, their suppliers and their transport providers
                        can share information in relation to ordering,{' '}
                        <span style={{ fontFamily: 'Arial' }}>
                          planning, coordinating and tracking the transport of
                          goods
                        </span>{' '}
                        (the “<strong>System</strong>”). The System is provided
                        in accordance with these terms of use (the “
                        <strong>Terms</strong>”).
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        These Terms govern the relationship between you (the “
                        <strong>Customer</strong>” or “<strong>you</strong>”)
                        and Volvo Truck Corporation, a company incorporated in
                        Sweden under company registration number 556013-9700,
                        and any company within the AB Volvo group of companies
                        (“<strong>Volvo</strong>”, “<strong>we</strong>” or “
                        <strong>us</strong>”), in relation to the access to, and
                        use of, the System.{' '}
                        <u>
                          Please read the Terms carefully before proceeding with
                          your access to and use of the System.
                        </u>
                        &nbsp;
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        Volvo reserves the right to amend the Terms at any time.
                        Any new versions of the Terms will be published in
                        the&nbsp;System, with or without notice, and it is your
                        responsibility to regularly check the Terms for updates
                        and changes. Your continued use of&nbsp;the System
                        <strong>&nbsp;</strong>following the publishing of a new
                        version of these Terms will mean that you accept and
                        agree to the changes.&nbsp;
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        By accessing and using the System, you confirm that you
                        have reviewed the Terms and that you agree to be bound
                        by them. If you do not agree with them, you are not
                        allowed to use the System and the services provided in
                        connection thereto.
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        <strong>System description.</strong> The System is a
                        platform where companies track goods ordered from its
                        suppliers via a QR code. The supplier places the QR code
                        on the package of goods to be delivered to the company.
                        The driver of the transport provider engaged by the
                        company or the company’s supplier shall scan the QR code
                        via the System’s mobile application when picking up the
                        goods, which will initiate the tracking of the GPS
                        positioning of the mobile device used by the driver to
                        scan the QR code.&nbsp;
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        <strong>System collects GPS positioning.</strong> The
                        System will collect the GPS positioning of the mobile
                        device in accordance with pre-determined intervals based
                        on the mobile device’s proximity to the delivery site.
                        The purpose for processing the GPS positioning is to
                        enable the System to calculate an estimated time of
                        arrival to the delivery site and re-calculate the
                        estimated time of arrival during the transport. The
                        company that the goods are delivered to will receive an
                        estimated time of arrival that is updated during the
                        transport, and will be able to see the GPS positioning
                        of the driver when the estimated time of arrival is one
                        hour or less. The contact details and image (if
                        applicable) for the driver, supplier representatives and
                        company representatives are shared via the System’s
                        mobile application. The tracking of the GPS positioning
                        of the driver’s mobile device, and sharing of contact
                        details amongst the aforementioned roles is conducted in
                        order to facilitate the delivery of the goods at the
                        company’s site.&nbsp;
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        <strong>
                          Option to pause the tracking of the GPS positioning.
                        </strong>{' '}
                        The driver has the option to pause the tracking of the
                        GPS positioning at any time during the transport,
                        however, doing so will affect the value and use of the
                        System.
                      </h2>
                    </li>
                  </ol>
                </li>
                <li>
                  <h1
                    style={{
                      marginTop: '6.0pt',
                      marginRight: '0cm',
                      marginBottom: '6.0pt',
                      marginLeft: '42.55pt',
                      textIndent: '-42.55pt',
                      fontSize: '1.3rem',
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    The System
                  </h1>
                  <ol
                    className="decimal_type"
                    style={{ listStyleType: 'undefined' }}
                  >
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        Volvo may, at its sole discretion, make new features
                        available through the System and cancel existing
                        features.&nbsp;
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        Volvo may, in its discretion, make changes to the
                        System. When we make changes that we consider material,
                        we will notify you. By continuing to use the System
                        after those changes are made, you are expressing and
                        acknowledging your acceptance of the changes. If you do
                        not agree to the changes and these Terms, you may not
                        continue to use the System.&nbsp;
                      </h2>
                    </li>
                  </ol>
                </li>
                <li>
                  <h1
                    style={{
                      marginTop: '6.0pt',
                      marginRight: '0cm',
                      marginBottom: '6.0pt',
                      marginLeft: '42.55pt',
                      textIndent: '-42.55pt',
                      fontSize: '1.3rem',
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Your use of the System
                  </h1>
                  <ol
                    className="decimal_type"
                    style={{ listStyleType: 'undefined' }}
                  >
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        When creating an account and using the System you will
                        provide certain data, as listed in the Privacy Notice.
                        In order to provide the System, we will use and process
                        the provided data. We will always use your data in a way
                        that is fair and worthy your trust. In order to provide
                        different functionalities of the System, the information
                        you provide may also be accessible for other users.
                        &nbsp;
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        By accepting the Terms you grant Volvo&nbsp;a
                        non-exclusive, free of charge and transferrable license
                        to store, use, reproduce, transfer and display the
                        information and material you provide Volvo through your
                        use of the System.
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        When using the System you undertake to:&nbsp;
                      </h2>
                      <ol
                        className="decimal_type"
                        style={{ listStyleType: 'undefined' }}
                      >
                        <li>
                          <h4
                            style={{
                              marginTop: '6.0pt',
                              marginRight: '0cm',
                              marginBottom: '12.0pt',
                              marginLeft: '42.55pt',
                              textAlign: 'justify',
                              textIndent: '0cm',
                              fontSize: '1.3rem',
                              fontFamily: '"Arial",sans-serif',
                              fontWeight: 'normal',
                            }}
                          >
                            represent and agree that the System will be used
                            only by you or other individuals you authorize to
                            use the System on your behalf;
                          </h4>
                        </li>
                        <li>
                          <h4
                            style={{
                              marginTop: '6.0pt',
                              marginRight: '0cm',
                              marginBottom: '12.0pt',
                              marginLeft: '42.55pt',
                              textAlign: 'justify',
                              textIndent: '0cm',
                              fontSize: '1.3rem',
                              fontFamily: '"Arial",sans-serif',
                              fontWeight: 'normal',
                            }}
                          >
                            ensure that the information you provide to Volvo is
                            accurate and up-to-date, and that you or any user
                            you authorize have the right to provide such
                            information to Volvo;&nbsp;
                          </h4>
                        </li>
                        <li>
                          <h4
                            style={{
                              marginTop: '6.0pt',
                              marginRight: '0cm',
                              marginBottom: '12.0pt',
                              marginLeft: '42.55pt',
                              textAlign: 'justify',
                              textIndent: '0cm',
                              fontSize: '1.3rem',
                              fontFamily: '"Arial",sans-serif',
                              fontWeight: 'normal',
                            }}
                          >
                            not insert any information in a free text field
                            relating directly or indirectly to an individual
                            (personal data) unless specifically requested.
                            Personal data can be for example name, contact
                            details, photo, vehicle identification number (VIN),
                            and vehicle registration number.
                          </h4>
                        </li>
                        <li>
                          <h4
                            style={{
                              marginTop: '6.0pt',
                              marginRight: '0cm',
                              marginBottom: '12.0pt',
                              marginLeft: '42.55pt',
                              textAlign: 'justify',
                              textIndent: '0cm',
                              fontSize: '1.3rem',
                              fontFamily: '"Arial",sans-serif',
                              fontWeight: 'normal',
                            }}
                          >
                            follow any written instructions for the use of the
                            System provided by Volvo from time to time; and
                          </h4>
                        </li>
                        <li>
                          <h4
                            style={{
                              marginTop: '6.0pt',
                              marginRight: '0cm',
                              marginBottom: '12.0pt',
                              marginLeft: '42.55pt',
                              textAlign: 'justify',
                              textIndent: '0cm',
                              fontSize: '1.3rem',
                              fontFamily: '"Arial",sans-serif',
                              fontWeight: 'normal',
                            }}
                          >
                            follow any laws and regulations that apply when
                            using the System, including but not limited to
                            applicable competition law(s) and not use the System
                            for anti-competitive purposes.&nbsp;
                          </h4>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        When using the System, you are responsible for
                        maintaining the confidentiality of your account. You
                        undertake to, at all times, ensure that your user
                        credentials (such as user name and password) are kept
                        confidential and secure that no unauthorized third
                        parties may access your user credentials or your
                        account. You agree to accept responsibility for all
                        activities that occur on your account or under your
                        password. If your password is lost or stolen, or if you
                        suspect unauthorized use of your account by third
                        parties, please notify us and change your password
                        immediately.&nbsp;
                      </h2>
                    </li>
                  </ol>
                </li>
                <li>
                  <h1
                    style={{
                      marginTop: '6.0pt',
                      marginRight: '0cm',
                      marginBottom: '6.0pt',
                      marginLeft: '42.55pt',
                      textIndent: '-42.55pt',
                      fontSize: '1.3rem',
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Intellectual property rights
                  </h1>
                  <ol
                    className="decimal_type"
                    style={{ listStyleType: 'undefined' }}
                  >
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        Volvo is the owner and/or authorized user of all design,
                        text, content, information, selection and arrangement of
                        elements, organization, graphics, design, compilation
                        and other matters related to the System ("
                        <strong>Intellectual Property Rights</strong>"). Your
                        use of the System does not grant you a license to the
                        Intellectual Property Rights. Nothing contained in the
                        System should be construed as granting any license or
                        right to use any Intellectual Property Rights without
                        Volvo’s written permission or that of the third party
                        rights holder.
                      </h2>
                    </li>
                  </ol>
                </li>
                <li>
                  <h1
                    style={{
                      marginTop: '6.0pt',
                      marginRight: '0cm',
                      marginBottom: '6.0pt',
                      marginLeft: '42.55pt',
                      textIndent: '-42.55pt',
                      fontSize: '1.3rem',
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    No warranties
                  </h1>
                  <ol
                    className="decimal_type"
                    style={{ listStyleType: 'undefined' }}
                  >
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        The System is provided on an “as is” and “as available”
                        basis. No representations or warranties, whether
                        expressed or implied, are made regarding any aspects of
                        the System, including but not limited to, (i) implied
                        warranties of merchantability, fitness for a particular
                        purpose, title or non-infringement, or (ii) the
                        accuracy, availability and accessibility of the System.
                        Further, Volvo makes no representation or warranty that
                        the System will be error-free, free of viruses and other
                        harmful materials, nor that the System will operate
                        without interruption.&nbsp;
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        Volvo makes no representations with respect to the
                        accuracy of the data displayed in the System.
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        The System is dependent upon Internet connection. Due to
                        the nature of the Internet, uninterrupted connection
                        cannot be guaranteed and the feature may accordingly be
                        occasionally unavailable due to lost Internet
                        connection.
                      </h2>
                    </li>
                  </ol>
                </li>
                <li>
                  <h1
                    style={{
                      marginTop: '6.0pt',
                      marginRight: '0cm',
                      marginBottom: '6.0pt',
                      marginLeft: '42.55pt',
                      textIndent: '-42.55pt',
                      fontSize: '1.3rem',
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Indemnification and limitation of liability&nbsp;
                  </h1>
                  <ol
                    className="decimal_type"
                    style={{ listStyleType: 'undefined' }}
                  >
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        You agree to indemnify and hold Volvo, its officers,
                        directors, shareholders, predecessors, successors in
                        interest, employees, agents, subsidiaries and
                        affiliates, harmless from any demands, damage, loss,
                        liability, claims or expenses (including attorneys’
                        fees) made against Volvo for your use of the System in
                        violation of these Terms. &nbsp;
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        To the fullest extent permitted by law and unless
                        otherwise expressly provided in these Terms, Volvo shall
                        have no liability for any damage or loss of any kind
                        caused in connection with your use of the System,
                        regardless of how it was caused and whether such damage
                        or loss was foreseeable or not.
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        The limitation of liability in Clause 6.2 includes but
                        is not limited to liability for any special, indirect,
                        incidental, consequential damage or loss of any kind,
                        regardless of how it was caused and including but not
                        limited to, loss of profit, loss of reputation or
                        goodwill, loss of production, loss of business or
                        business opportunities, loss of revenues or anticipated
                        savings, or loss or corruption of data or information.
                      </h2>
                    </li>
                  </ol>
                </li>
                <li>
                  <h1
                    style={{
                      marginTop: '6.0pt',
                      marginRight: '0cm',
                      marginBottom: '6.0pt',
                      marginLeft: '42.55pt',
                      textIndent: '-42.55pt',
                      fontSize: '1.3rem',
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Data protection
                  </h1>
                  <ol
                    className="decimal_type"
                    style={{ listStyleType: 'undefined' }}
                  >
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        The Privacy Notice available in the “Privacy Notice and
                        Consent Form” contains additional information regarding
                        Volvo’s processing of any personal data, within the
                        meaning of the EU General Data Protection Regulation
                        2016/679 (the "<strong>GDPR</strong>").{' '}
                        <u>
                          Please read this privacy notice carefully before using
                          the System
                        </u>
                        .
                      </h2>
                    </li>
                  </ol>
                </li>
                <li>
                  <h1
                    style={{
                      marginTop: '6.0pt',
                      marginRight: '0cm',
                      marginBottom: '6.0pt',
                      marginLeft: '42.55pt',
                      textIndent: '-42.55pt',
                      fontSize: '1.3rem',
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Miscellaneous
                  </h1>
                  <ol
                    className="decimal_type"
                    style={{ listStyleType: 'undefined' }}
                  >
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        You may not assign nor transfer any part of your rights
                        or obligations under these Terms without the prior
                        written consent of Volvo.
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        Volvo may assign and/or transfer all of its rights and
                        obligations under these Terms.
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        If any part of these Terms is determined to be invalid
                        or unenforceable pursuant to governing law, including
                        but not limited to the limitation of liability set forth
                        above, the invalid or unenforceable provision will be
                        deemed superseded by a valid, enforceable provision that
                        most closely matches the wordings and spirit of the
                        original provision. The remainder of the Terms shall
                        continue in effect.
                      </h2>
                    </li>
                  </ol>
                </li>
                <li>
                  <h1
                    style={{
                      marginTop: '6.0pt',
                      marginRight: '0cm',
                      marginBottom: '6.0pt',
                      marginLeft: '42.55pt',
                      textIndent: '-42.55pt',
                      fontSize: '1.3rem',
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Term and termination
                  </h1>
                  <ol
                    className="decimal_type"
                    style={{ listStyleType: 'undefined' }}
                  >
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        These Terms shall apply between the Customer and Volvo
                        until terminated by either party by giving the other
                        party notice to that effect.
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        In particular, Volvo may terminate these Terms at any
                        time and with immediate effect in the event of actual or
                        suspected unauthorized use of the System, or the
                        non-compliance of Clause 3&nbsp;or Clause&nbsp;4&nbsp;of
                        these Terms.
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        If Volvo terminates these Terms pursuant to Clauses 9.1
                        or 9.2, you agree that Volvo shall have no liability or
                        responsibility to you to the fullest extent permitted by
                        applicable law.
                      </h2>
                    </li>
                  </ol>
                </li>
                <li>
                  <h1
                    style={{
                      marginTop: '6.0pt',
                      marginRight: '0cm',
                      marginBottom: '6.0pt',
                      marginLeft: '42.55pt',
                      textIndent: '-42.55pt',
                      fontSize: '1.3rem',
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Governing law and disputes
                  </h1>
                  <ol
                    className="decimal_type"
                    style={{ listStyleType: 'undefined' }}
                  >
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        These Terms shall be governed by and construed in
                        accordance with the laws of Sweden, excluding its
                        conflict of laws principles providing for the
                        application of the laws of any other jurisdiction.
                      </h2>
                    </li>
                    <li>
                      <h2
                        style={{
                          marginTop: '6.0pt',
                          marginRight: '0cm',
                          marginBottom: '6.0pt',
                          marginLeft: '0cm',
                          textAlign: 'justify',
                          textIndent: '0cm',
                          fontSize: '1.3rem',
                          fontFamily: '"Arial",sans-serif',
                          fontWeight: 'normal',
                        }}
                      >
                        The Swedish courts, with the district court of
                        Gothenburg (<em>Göteborgs tingsrätt</em>) as first
                        instance, will have exclusive jurisdiction to settle any
                        dispute which may arise out of, or in connection with
                        this Agreement. The parties agree to submit to that
                        jurisdiction.
                      </h2>
                    </li>
                  </ol>
                </li>
              </ol>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.9rem' }}>
                    Privacy Notice and Consent Form for participation in Clinics
                  </span>
                </strong>
                <span style={{ fontSize: '2.9rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>
                    Research clinic information&nbsp;
                  </span>
                </strong>
                <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '0cm',
                  marginLeft: '25.5pt',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Volvo Truck Corporation (“VOLVO”) kindly asks you to
                  participate in a research clinic, which will be further
                  explained below. The clinic is a part of our research and
                  therefore your participation is highly appreciated. To VOLVO
                  it is of great importance to protect your personal data and to
                  ensure that our processing of your personal data is conducted
                  in a correct and legitimate way.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Your consent is necessary for us to be able to process GPS
                  positioning data about you from the clinic. Please read this
                  document carefully and thereafter, if you wish to provide
                  VOLVO with your consent to process GPS positioning data,
                  please tick the checkbox that is made available when creating
                  a user account, which is accompanied with the text displayed
                  on the last page of this document. By ticking that checkbox,
                  you provide VOLVO with your explicit, written consent to
                  process the personal data set out in the Consent Form for the
                  purposes that will be further explained below. &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>
                    Description of the clinic&nbsp;
                  </span>
                </strong>
                <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '0cm',
                  marginLeft: '27.75pt',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  VOLVO will conduct a research clinic for evaluation of a
                  platform consisting of a mobile application to be used for
                  ordering, planning, coordinating and tracking the transport of
                  goods (the “System”).&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  The clinic may include the following tasks for you to perform:
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <ul style={{ listStyleType: 'disc', marginLeft: '26px' }}>
                <li>
                  <span
                    style={{
                      fontFamily: '"Helvetica",sans-serif',
                      fontSize: '11.0pt',
                    }}
                  >
                    Download the System’s mobile application on a mobile device;
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Helvetica",sans-serif',
                      fontSize: '11.0pt',
                    }}
                  >
                    As a driver of a transport provider, scan a QR code provided
                    by the supplier when picking up goods with your mobile
                    device via the System’s mobile application, that will
                    initiate a tracking of GPS positioning data of your mobile
                    device; and
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Helvetica",sans-serif',
                      fontSize: '11.0pt',
                    }}
                  >
                    As a driver of a transport provider, transport such goods to
                    the designated delivery site.
                  </span>
                </li>
              </ul>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>
                    Categories of personal data that VOLVO will obtain
                  </span>
                </strong>
                <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  If data that are input into or generated from the use of the
                  System could be connected to you as an individual, such data
                  would be considered to be personal data. VOLVO will process
                  the following categories of personal data in relation to your
                  use of the System. Please note that VOLVO will not necessarily
                  process all the data listed below.
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <ul style={{ listStyleType: 'disc', marginLeft: '26px' }}>
                <li>
                  <strong>
                    <span style={{ fontSize: '11.0pt' }}>User data</span>
                  </strong>
                  <span style={{ fontSize: '11.0pt' }}>
                    , such as name, email address, telephone number, password,
                    user application preferences, and image (image is
                    voluntary);
                  </span>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '11.0pt' }}>Driver data</span>
                  </strong>
                  <span style={{ fontSize: '11.0pt' }}>
                    , such as email address, telephone number, GPS positioning
                    data of your mobile device when transporting goods to a
                    delivery site;
                  </span>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '11.0pt' }}>
                      Delivery site data
                    </span>
                  </strong>
                  <span style={{ fontSize: '11.0pt' }}>
                    , such as company name, location, drop points (longitude and
                    latitude), image, and contact persons from the companies
                    involved in the delivery; and
                  </span>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '11.0pt' }}>Vehicle data</span>
                  </strong>
                  <span style={{ fontSize: '11.0pt' }}>
                    , such as registration number, model, and type.
                  </span>
                </li>
              </ul>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  The System includes some free text fields. Please be advised
                  that care should be taken when entering any information in
                  free text fields. Any information relating to an identified or
                  identifiable person should be limited to factual information
                  that is directly related to and necessary for the intended
                  purposes. Furthermore, you should comply with the following
                  principles.
                </span>
              </p>
              <ul style={{ listStyleType: 'disc', marginLeft: '26px' }}>
                <li>
                  <span
                    style={{
                      fontFamily: '"Helvetica",sans-serif',
                      fontSize: '11.0pt',
                    }}
                  >
                    You should feel comfortable in disclosing all information in
                    the free text field to the individual to whom the
                    information relates.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Helvetica",sans-serif',
                      fontSize: '11.0pt',
                    }}
                  >
                    Information containing statements of opinion and/or value
                    judgements regarding individuals should be avoided.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Helvetica",sans-serif',
                      fontSize: '11.0pt',
                    }}
                  >
                    You should not enter information into a free text field that
                    could constitute sensitive personal data (i.e. information
                    as to (a) the racial or ethnic origin of an individual, (b)
                    their political opinions, (c) their philosophical or
                    religious beliefs, (d) whether they are a member of a trade
                    union, (e) genetic and biometric data, (f) their physical or
                    mental health and (g) their sexual life) or information
                    about criminal offences.
                  </span>
                </li>
              </ul>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '0cm',
                  marginLeft: '27.75pt',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>
                    Purposes of the processing
                  </span>
                </strong>
                <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontFamily: '"Times New Roman",serif' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span
                    style={{
                      fontSize: '1.5rem',
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Evaluate the System and perform research and development for
                    improvement of the System as well as new services, products
                    and features&nbsp;
                  </span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                    background: 'yellow',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  VOLVO uses the concerned categories of personal data in order
                  to evaluate and further develop and make improvements to the
                  System as well as developing new products, services and
                  functionalities.
                </span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '0cm',
                  marginLeft: '27.75pt',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>
                    Legal basis for the processing of your personal data
                  </span>
                </strong>
                <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  VOLVO will process your personal data based on either of the
                  following legal grounds and for the following purposes, see
                  also further details below.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span
                    style={{
                      fontSize: '1.5rem',
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Legitimate interests
                  </span>
                </strong>
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  . VOLVO’s legitimate interests include the interest to process
                  your personal data in relation to the use of the System in
                  order to evaluate and further develop and make improvements to
                  the System as well as developing new products, services and
                  functionalities.
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span
                    style={{
                      fontSize: '1.5rem',
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Consent
                  </span>
                </strong>
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  . VOLVO’s processing of the GPS positioning data of your
                  mobile device for this clinic is based on your consent. If
                  VOLVO would not obtain your consent for the purpose(s) set out
                  below under the section “Consent to processing of personal
                  data”, VOLVO will not process any GPS positioning data about
                  you for this specific clinic and you will therefore not be
                  able to participate in the clinic. &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  It is possible to pause the tracking of GPS positioning data
                  of your mobile device during a transport, however, please note
                  that doing so will affect the value and use of the evaluation
                  of the System.
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.2rem',
                    fontFamily: '"Segoe UI",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>
                    From where will VOLVO obtain your personal data?
                  </span>
                </strong>
                <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontFamily: '"Times New Roman",serif' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  VOLVO processes personal data that you have provided to us
                  yourself. VOLVO further processes personal data collected from
                  your mobile device and from your use of the System.
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>
                    Sharing of your personal data
                  </span>
                </strong>
                <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontFamily: '"Times New Roman",serif' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  VOLVO will normally not share your personal data with anyone
                  outside of the Volvo Group, if it is not required by law,
                  regulation or collective bargaining agreement. However, VOLVO
                  may, if necessary to fulfill the purpose for processing the
                  personal data, share your personal data with other Volvo Group
                  company, including company outside of EU/EEA. VOLVO may also,
                  if necessary to fulfill the purpose for processing the
                  personal data, share your personal data with third party
                  suppliers, including suppliers outside of EU/EEA.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  In order to conduct this clinic, VOLVO will share the
                  concerned categories of personal data with the company that
                  are recipients of the goods that are transported. Furthermore,
                  VOLVO will share contact details from participants in the
                  clinic with the company ordering goods, the supplier shipping
                  goods, and the driver delivering the goods to a company.
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  VOLVO will in all cases use all reasonable endeavors to
                  satisfy itself that there are appropriate safeguards in place
                  which provide adequate levels of protection of your personal
                  data as required by applicable data protection laws. For
                  example, this may include the use of intercompany or external
                  data processing agreements based on EU approved Standard
                  Contractual Clauses or such other mechanisms as have been
                  recognized or approved by the relevant authorities from time
                  to time. If you have questions about the transfer, please
                  contact VOLVO’s&nbsp;Group Privacy Officer
                </span>
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  .&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.2rem',
                    fontFamily: '"Segoe UI",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>
                    Storage of your personal data
                  </span>
                </strong>
                <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Data connected to this clinic will be processed for as long as
                  you have a user account registered for the System, and up to 3
                  months after the termination of your user account.
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>Your rights</span>
                </strong>
                <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Your participation in this clinic is voluntary. You have the
                  right to withdraw your consent at any time without giving any
                  reason. Furthermore, you have the right to request access to
                  your personal data. You also have the right to request VOLVO
                  to rectify or erase your personal data or restrict the
                  processing of the same. You may also object to VOLVO’s
                  processing of your personal data or request data portability.
                  However, please note that VOLVO may not always be obliged to
                  comply with a request of deletion, restriction, objection or
                  data portability. Assessment will be made on a case by case
                  basis of VOLVO’s legal obligations and the exception to such
                  rights.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  In addition, you have the right to lodge any complaints you
                  may have about VOLVO’s processing of your personal data to the
                  supervisory authority. &nbsp;&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  For more information about your rights and how to exercise
                  them, please contact the Group Privacy Officer (see contact
                  details below). &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>
                    Identity and contact details of the controller,&nbsp;Group
                    Privacy Officer&nbsp;and Clinic Manager
                  </span>
                </strong>
                <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '0cm',
                  marginLeft: '27.75pt',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontFamily: '"Times New Roman",serif' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Volvo Truck Corporation
                </span>
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  ,
                </span>
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;official registration number 556013-9700, Volvo Group
                  Connected Solutions AB, official registration number
                  559153-7823, and AB Volvo, official registration number
                  556012-5790, (collectively referred to as “
                  <strong>VOLVO</strong>”) is the data controller for the data
                  processed about you in this research clinic. This means that
                  VOLVO is responsible for the processing of such personal data
                  under applicable data protection laws and regulations.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Contact details VOLVO: &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Volvo Truck Corporation &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  405 08 Gothenburg&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Sweden &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  If you have any questions about the processing of your
                  personal data, if wish to cancel the processing of your
                  personal data or if you otherwise would like to exercise your
                  rights (stated above in this Privacy Notice &amp; Consent
                  Form) you are welcome to contact our&nbsp;Group Privacy
                  Officer
                </span>
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  : &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  VOLVO’s Group Privacy Officer&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Email:&nbsp;
                </span>
                <a href="mailto:GPO.Office@volvo.com" target="_blank">
                  <span
                    style={{
                      fontFamily: '"Helvetica",sans-serif',
                      color: 'blue',
                    }}
                  >
                    GPO.Office@volvo.com
                  </span>
                </a>
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Address: AB Volvo, Att: Group Privacy Office, Dept AA14100,
                  VGHQ, SE-405 08 Gothenburg, Sweden &nbsp;&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Phone: +46 (0)31 66 00 00&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '0cm',
                  marginLeft: '27.75pt',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  If you have questions concerning the clinic in general, please
                  contact:&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span
                    style={{
                      fontSize: '1.5rem',
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Ida Karnerfors &nbsp; &nbsp; &nbsp; &nbsp; Volvo Truck
                    Corporation
                  </span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  textIndent: '27.75pt',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Office phone: +46&nbsp;(0)76 - 553 87 59
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  textIndent: '27.75pt',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Email:&nbsp;ida.karnerfors@volvo.com
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  textIndent: '27.75pt',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Website:&nbsp;
                </span>
                <a href="http://www.volvogroup.com/" target="_blank">
                  <span
                    style={{
                      fontSize: '1.5rem',
                      fontFamily: '"Helvetica",sans-serif',
                      color: '#436976',
                      textDecoration: 'none',
                    }}
                  >
                    www.volvogroup.com
                  </span>
                </a>
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p>
                <strong>
                  <span
                    style={{
                      fontSize: '2.1rem',
                      lineHeight: '107%',
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    <br />
                    &nbsp;
                  </span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '8.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  lineHeight: '107%',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem', lineHeight: '107%' }}>
                    &nbsp;
                  </span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <span style={{ fontSize: '2.1rem' }}>
                    Consent to processing your personal data (applicable for
                    drivers transporting goods)
                  </span>
                </strong>
                <span style={{ fontSize: '2.1rem' }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <strong>
                  <em>
                    <span
                      style={{
                        fontSize: '1.5rem',
                        fontFamily: '"Helvetica",sans-serif',
                        color: '#244061',
                      }}
                    >
                      &nbsp;
                    </span>
                  </em>
                </strong>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  I have read and understood the information provided in the
                  Privacy Notice. By ticking the relevant checkbox below and
                  creating an account, I hereby consent to VOLVO’s processing of
                  my personal data from the clinic for the following purposes
                  (consent is optional): &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '0cm',
                  marginLeft: '25.5pt',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  <span style={{ fontFamily: '"MS Gothic"' }}>☐</span>GPS
                  positioning data of your mobile device
                </span>
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;will be used for the purpose of&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  evaluating and further developing and making improvements to
                  the System as well as developing new products, services and
                  functionalities.
                </span>
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.2rem',
                    fontFamily: '"Segoe UI",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'left',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Please note that you have the right to withdraw your consent
                  at any time. Such withdrawal does not affect processing
                  activities prior to the withdrawal.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'center',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'center',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span style={{ fontSize: '1.5rem', fontFamily: 'SimSun' }}>
                  &nbsp;
                </span>
                <strong>
                  <em>
                    <span
                      style={{
                        fontSize: '1.5rem',
                        fontFamily: '"Helvetica",sans-serif',
                      }}
                    >
                      Thank you!
                    </span>
                  </em>
                </strong>
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'center',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'center',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  We appreciate your participation.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'center',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'center',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                  margin: '0cm',
                  verticalAlign: 'baseline',
                }}
              >
                <span
                  style={{
                    fontSize: '1.5rem',
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '6.0pt',
                  marginRight: '0cm',
                  marginBottom: '12.0pt',
                  marginLeft: '0cm',
                  textAlign: 'justify',
                  fontSize: '1.3rem',
                  fontFamily: '"Arial",sans-serif',
                }}
              >
                &nbsp;
              </p>
            </div>
          </div>
        </section>
      </main>
    </section>
  )
}

export default PrivacyPolicy
